import './App.css';
import { Navbar } from './components/Navbar';
import { MainLanding } from './components/MainLanding';
import { Box } from '@mui/material';
import { Footer } from './components/Footer';

function App() {
  return (
    <>
      <Box className="App" minHeight="80vh" width="100%">
        <MainLanding />
      </Box>
    </>
  );
}

export default App;
