import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Logo } from '../logo.svg';
import Typography from '@mui/material/Typography';

export function Navbar() {
  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        sx={{ alignItems: 'start' }}
        color="primary"
        enableColorOnDark={true}>
        <Toolbar sx={{ pl: { xs: '2%', sm: '3%', md: '4%' } }} disableGutters>
          <SvgIcon component={Logo} fontSize="large" sx={{ mr: 1 }} inheritViewBox />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}>
            Gymstro
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
