import { deepmerge } from '@mui/utils';
import { createTheme, Theme } from '@mui/material';

// ref: https://github.com/mui/material-ui/blob/a82d7fb048b366eee89a9435df5224ab17137127/docs/src/modules/brandingTheme.ts

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorRange {}

  interface Palette {
    primaryDark: PaletteColor;
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export function getThemedComponents(theme: Theme) {
  return {
    palette: {},
    components: {},
  };
}

export const appTheme = deepmerge(defaultTheme, getThemedComponents(defaultTheme));
