import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export function Footer() {
  return (
    <Box
      paddingX={{ sm: '2%', md: '5%' }}
      paddingBottom={'1em'}
      paddingTop={'2em'}
      alignItems={'center'}
      bottom={0}
      bgcolor={'#333333'}
      sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Grid container spacing={2} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <Link href="privacy" variant="subtitle1" fontWeight={300} color="#ffffff" paddingX={2}>
            Privacy Policy
          </Link>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" fontWeight={300} color="#ffffff" paddingX={2}>
            Copyright © 2023 Gymstro. All rights reserved.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" fontWeight={600} color="#eeeeee" paddingX={2}>
            contact@gymstro.app
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
