import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import landing1 from '../assets/landing1.png';
import landing2 from '../assets/landing2.png';
import landing3 from '../assets/landing3.png';
import appStoreDownload from '../assets/download_on_app_store.svg';
import playStoreDownload from '../assets/google-play-badge.svg';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';

export function MainLanding() {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Box textAlign="start">
        <Box paddingLeft={{ sm: '2%', md: '5%' }}>
          <Typography variant="h4" fontWeight={350} color="#555555" padding={2}>
            Your new workout journal.
          </Typography>
          <Typography variant="h5" fontWeight={350} color="#555555" paddingLeft={2}>
            Simple. Minimal. Always free.
          </Typography>
        </Box>

        <ImageList cols={matches ? 3 : 1} sx={{ paddingX: '3%', maxWidth: '100%' }}>
          <ImageListItem sx={{ padding: '8%' }}>
            <img src={landing1} />
            <ImageListItemBar
              title={
                <Typography variant="h5" fontWeight={350} color="#555555" padding={2}>
                  Reach your goals.
                </Typography>
              }
              position="below"
              sx={{ textAlign: 'center' }}
            />
          </ImageListItem>
          <ImageListItem sx={{ padding: '8%' }}>
            <img src={landing2} />
            <ImageListItemBar
              title={
                <Typography variant="h5" fontWeight={350} color="#555555" padding={2}>
                  Log workouts.
                </Typography>
              }
              position="below"
              sx={{ textAlign: 'center' }}
            />
          </ImageListItem>
          <ImageListItem sx={{ padding: '8%' }}>
            <img src={landing3} />
            <ImageListItemBar
              title={
                <Typography variant="h5" fontWeight={350} color="#555555" padding={2}>
                  Track progress.
                </Typography>
              }
              position="below"
              sx={{ textAlign: 'center' }}
            />
          </ImageListItem>
        </ImageList>
      </Box>
      <Grid container spacing={2} padding={'3%'} justifyContent={'center'} alignItems={'center'}>
        <Grid item sx={{ width: { xs: '50%', md: '22.5%' } }}>
          <a
            href="https://apps.apple.com/us/app/gymstro/id6449997830"
            target="_blank"
            rel="noreferrer">
            <img src={appStoreDownload} width={'91%'} style={{ margin: '6%' }} />
          </a>
        </Grid>
        <Grid item sx={{ width: { xs: '50%', md: '22.5%' } }}>
          <a
            href="https://play.google.com/store/apps/details?id=com.gymstro.app.prod"
            target="_blank"
            rel="noreferrer">
            <img src={playStoreDownload} width={'100%'} />
          </a>
        </Grid>
      </Grid>
    </>
  );
}
