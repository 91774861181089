import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <Navbar />
      <RouterProvider router={router} />
      <Footer />
    </ThemeProvider>
  </React.StrictMode>
);

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import ErrorPage from './pages/error-page';
import { ThemeProvider } from '@mui/material';
import { appTheme } from './appTheme';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import PrivacyPolicy from './pages/privacy-policy';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyABv7Fp1zcvGr8ILVK_n8NlDtJanUiKA4U',
  authDomain: 'gymstro-prod.firebaseapp.com',
  projectId: 'gymstro-prod',
  storageBucket: 'gymstro-prod.appspot.com',
  messagingSenderId: '516928346456',
  appId: '1:516928346456:web:b509c8f262e8898137ed2d',
  measurementId: 'G-1TEE03YMJS',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
