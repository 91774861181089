import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import privacyPolicyPath from '../assets/privacy_policy.md';

export default function PrivacyPolicy() {
  const [md, setMd] = useState('');

  useEffect(() => {
    fetch(privacyPolicyPath)
      .then((response) => response.text())
      .then((text) => {
        setMd(text);
      });
  }, []);

  return (
    <Box textAlign="start" paddingX={{ sm: '2%', md: '5%' }} paddingBottom={'5%'} flexGrow={1}>
      <ReactMarkdown>{md}</ReactMarkdown>
    </Box>
  );
}
